import errorLogo from '../assets/404-error-page-meteor.png'

const ErrorPage = () => {
  return (
    <div className="error-page">
      <img src={errorLogo} alt="" />
    </div>
  )
}

export default ErrorPage
