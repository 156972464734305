import React from 'react'

const Data = ({user}) => {
    console.log(user)
  return (
    <div>
      {user && <h1>{user}</h1>}
    </div>
  )
}

export default Data
