import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import './App.css'
import Data from './componens/Data';
import ErrorPage from './componens/ErrorPage';

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Data user="rootocska" />} />
          <Route path="/308f159f-8e67-4df4-8410-9ab6831b1793" element={<Data user="levi" />} />
          <Route path="/a3bd00c0-e29d-41e2-84af-c3a44dbc903e" element={<Data user="nomi" />} />
          <Route path="/195162a0-879d-4e60-a8a1-6136174ef747" element={<Data user="isti" />} />
          <Route path="/1f2aa25c-c69a-47e8-b79b-a0a5d5945ba9" element={<Data user="aladar" />} />
          <Route path="*" element={<ErrorPage />} /> 
        </Routes>
      </Router>
  )
}

export default App
